import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hybrit-code-standards"
    }}>{`HybrIT Code Standards`}</h1>
    <p>{`At HybrIT we try to achieve the best possible result in our projects. A important staple of that is writing "good", "clean" and "secure" code. You can follow the following principle:`}</p>
    <p><inlineCode parentName="p">{`How much effort is required for another developer of comparable experience to pick up where the previous developer left off to fix, enhance or build upon the source code - without involving the previous developer and taking into account the lifetime, quality, security and the business impact of the application.`}</inlineCode></p>
    <p>{`With this is mind we have the following "simple" rules for all our developers to follow as a start:`}</p>
    <h3 {...{
      "id": "1-code-is-simple-and-concise"
    }}>{`1. Code is simple and concise`}</h3>
    <p>{`Code should be simple and easy to read for another developer. Trying to take coding-shortcuts to decrease the number of lines of code but impair the ability of a different developer to read the functionality of the code is a bad practice. `}</p>
    <h3 {...{
      "id": "2-dry---do-not-repeat-yourself"
    }}>{`2. DRY - 'Do not repeat yourself'`}</h3>
    <p>{`You should try to reduce duplication of code as much as possible. Having the same functionality on two different places means you can refactor your code and extract a function. This improves readability and maintenance of your software.`}</p>
    <h3 {...{
      "id": "3-code-and-code-changes-are-self-explanatory"
    }}>{`3. Code and code changes are self-explanatory`}</h3>
    <p>{`If a developer writes code it should be obvious what the functionality of the code is. If a hack or workaround is implemented, the why of the change should be documented not the how.`}</p>
    <p>{`Remember: while comments are very important, the best code is self-documenting. Giving sensible names to types and variables is much better than using obscure names that you must then explain through comments`}</p>
    <h3 {...{
      "id": "4-if-if-if-if-if-else-"
    }}>{`4. If If If If If Else ?`}</h3>
    <p>{`Everytime you introduce an extra branch in an if statement you increase the number of paths your code can take by 1. This increases the complexity and thus the chance a bug can occur in one of the different branches.`}</p>
    <p>{`Only use an else statement if required. Prefer a switch statement over multiple if-then-else constructs.`}</p>
    <h3 {...{
      "id": "5-let-your-ide-do-the-work-for-you"
    }}>{`5. Let your IDE do the work for you`}</h3>
    <p>{`Regardless of the IDE you prefer to work with, let the IDE do the work for you. Install the proper linting tools and rules for the framework / language you are working with and follow the guidelines. This will increase the readability of your code and helps your fellow developers.`}</p>
    <p>{`If you do not agree with a certain rule used ( tabs vs spaces?) do not go cowboy in a new project but create a pull request with your changes and open up the discussion.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      